import { Show, Switch, createEffect, createSignal, onCleanup, onMount, splitProps } from "solid-js";
import { AccordionViewProps } from "../model";
import { Atom } from ":mods";

export function Accordion(props: AccordionViewProps) {
  const [local, others] = splitProps(props, [
    "title",
    "iconClass",
    "groupClass",
    "titleClass",
    "children",
    "class",
    "activeClass",
    "disabled",
    "disabledClass",
    "immediateLoad",
    "arrowMode",
    "dontCollapseOnOutsideClick",
    "iconRotationClass",
  ]);
  const [isOpen, setIsOpen] = createSignal(false);
  let ref: HTMLDivElement = undefined;
  // @ts-ignore
  const clickOutsideEvent$ = Atom.Directive.method.clickOutsideEvent$;
  createEffect(() => {
    if (local.disabled && isOpen()) {
      setIsOpen(false);
    }
  });
  const toggleAccordion = () => {
    if (local.disabled) {
      return;
    }

    setIsOpen((s) => !s);
  };

  return (
    <div
      {...others}
      ref={ref}
      use:clickOutsideEvent$={() => {
        if (!local.dontCollapseOnOutsideClick) {
          setIsOpen(false);
        }
      }}
      class={`relative
      ${local.class ?? ""} 
      ${isOpen() ? local.activeClass ?? " border-dashed border-r-2 border-l-2 border-b-2 " : ""}
      `}
    >
      <div
        class={`flex flex-row items-center
        ${local.groupClass ?? ""} 
        ${!local.disabled ? "cursor-pointer" : `opacity-70 cursor-not-allowed ${local.disabledClass ?? ""}`}
        `}
        onClick={toggleAccordion}
      >
        {!local.arrowMode && (
          <i
            class={`w-24px h-24px ${local.iconClass ?? " icon-raphael:arrowright "} ${
              isOpen() ? local.iconRotationClass?.rotated ?? "rotate-90" : local.iconRotationClass?.idle ?? ""
            }`}
          />
        )}
        <Show when={local.title}>
          <Show
            when={typeof local.title === "string"}
            fallback={
              typeof local.title !== "function"
                ? local.title
                : local.title?.({ isOpen: isOpen() }) ?? "please provide accordion title"
            }
          >
            <h3 class={local.titleClass}>{local.title as string}</h3>
          </Show>
        </Show>
        {local.arrowMode === "end" && (
          <i
            class={`w-24px h-24px ${local.iconClass ?? " icon-raphael:arrowleft "} ${
              isOpen() ? local.iconRotationClass?.rotated ?? "-rotate-90" : local.iconRotationClass?.idle ?? ""
            }`}
          />
        )}
      </div>
      <Show when={!local.disabled}>
        <Show when={local.immediateLoad} fallback={<Show when={isOpen()}>{local.children}</Show>}>
          <div class={`${isOpen() ? " visible " : " hidden "}`}>{local.children}</div>
        </Show>
      </Show>
    </div>
  );
}
